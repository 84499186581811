// _footer-root.scss

////= Footer/Root Styling
//============================================================================//

.root {
  // background: $secondary-bg-color;
  background: $primary-bg-color;
  font-size: $md-font-size;
  padding: pxem(65px) 0;

  small {
    float: none;
    margin: pxem(10px) 0 0;
  }
}
