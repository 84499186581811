// _work.scss

////= Work Module
//============================================================================//

.work-list {
  padding: pxem(55px) 0;

  .work {
    -moz-perspective: 1000;
    -o-perspective: 1000;
    -webkit-perspective: 1000;
    perspective: 1000;

    &:focus div,
    &:hover div {
      -moz-transform: rotateY(180deg);
      -o-transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }

  section {
    border: 1px solid rgba($black, 0.4);
    border-radius: $secondary-radius;
    color: $wild-sand;
    display: block;
    height: pxrem(230px);
    width: pxrem(230px);
  }

  div {
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -moz-transition: $primary-duration;
    -o-transition: $primary-duration;
    -webkit-transition: $primary-duration;
    transition: $primary-duration;
  }

  h6 {
    @extend .center-align;
    margin: pxem(10px) 0;
  }

  li {
    display: inline-block;
    margin: pxem(10px) auto;
    vertical-align: middle;

    @include respond-to('xxs') {
      margin: pxem(10px) pxem(100px);
    }

    @include respond-to('sm') {
      margin: 0 pxem(7.5px);
    }

    @include respond-to('md') {
      margin: 0 pxem(20px);
    }
  }

  p {
    line-height: 1.35;
    padding: 0 pxem(20px) pxem(20px);
    text-align: left;
  }

  .project {
    [data-work="work-front"] {
      background-image: -webkit-linear-gradient(top left, $fuchsia, $electric-violet);
      background-image: linear-gradient(to bottom right, $fuchsia, $electric-violet);
    }
  }

  .application {
    [data-work="work-front"] {
      background-image: -webkit-linear-gradient(top right, $fuchsia, $electric-violet);
      background-image: linear-gradient(to bottom left, $fuchsia, $electric-violet);
    }
  }

  .website {
    [data-work="work-front"] {
      background-image: -webkit-linear-gradient(top right, $electric-violet, $fuchsia);
      background-image: linear-gradient(to bottom left, $electric-violet, $fuchsia);
    }
  }

  .icon-link,
  .icon-social-github {
    background-color: $clear;
    color: $secondary-color;
    font-size: pxem(25px);
    z-index: z('over');
  }

  [data-work="work-front"],
  [data-work="work-back"] {
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    left: 0;
    top: 0;
  }

  [data-work="work-front"] {
    .icon-link,
    .icon-social-github {
      margin: pxem(-5px) pxem(20px) 0;
    }
  }

  [data-work="work-back"] {
    position: absolute;
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);

    .icon-link,
    .icon-social-github {
      margin: pxem(110px) pxem(20px) 0;
    }
  }
}

.project-list,
.website-list {
  background-color: $secondary-bg-color;
}

.application-list {
  background-color: $primary-bg-color;
}
