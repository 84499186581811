// _about-page.scss

////= About Page Styling
//============================================================================//

.about {
  &__photo {

    @include respond-to('sm') {
      border-radius: $round-radius;
      max-width: pxem(190px);
    }
  }
}
