// _glyphs.scss

////= Additional Fonts and Icons
//============================================================================//

//== Additional Fonts =========================================================/

/// Loads Aleo Regular font
@font-face {
  font-family: 'Aleo-regular';
  src: url($path-glyph + '/aleo-regular-webfont.eot');
  src: url($path-glyph + '/aleo-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url($path-glyph + '/aleo-regular-webfont.woff2') format('woff2'),
       url($path-glyph + '/aleo-regular-webfont.woff') format('woff'),
       url($path-glyph + '/aleo-regular-webfont.ttf') format('truetype'),
       url($path-glyph + '/aleo-regular-webfont.svg.svg#Aleo-regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/// Loads Inconsolata Regular font
@font-face {
  font-family: 'Inconsolata';
  src: url($path-glyph + '/Inconsolata-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/// Loads Inconsolata Bold font
@font-face {
  font-family: 'Inconsolata';
  src: url($path-glyph + '/Inconsolata-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/// Loads Roboto Regular font
@font-face {
  font-family: 'Roboto-regular';
  src: url($path-glyph + '/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//== Additional Icons =========================================================/

/// Loads Simple Line Icons
@font-face {
  font-family: 'Simple-line-icons';
  src: url($path-glyph + '/Simple-Line-Icons.eot?v=2.2.2');
  src: url($path-glyph + '/Simple-Line-Icons.eot?#iefix&v=2.2.2') format('embedded-opentype'),
        url($path-glyph + '/Simple-Line-Icons.ttf?v=2.2.2') format('truetype'),
        url($path-glyph + '/Simple-Line-Icons.woff2?v=2.2.2') format('woff2'),
        url($path-glyph + '/Simple-Line-Icons.woff?v=2.2.2') format('woff'),
        url($path-glyph + '/Simple-Line-Icons.svg?v=2.2.2#Simple-line-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
