// _blurb.scss

////= Blurb Module
//============================================================================//

.blurb {
  background: $secondary-bg-color;

  li {
    line-height: 1.8;
  }

  ul {
    padding: pxem(25px);
  }
}
