// _home-page.scss

////= Home Page Styling
//============================================================================//

.home {
  body {
    @extend .center-align;

    h1,
    h2,
    h3 {
      font-family: $main-heading-font;
      line-height: 1.6;
    }

    h4,
    h5,
    h6 {
      font-family: $sub-heading-font;
      line-height: 1.5;
    }

    h1 {
      font-size: 2.600em;
      margin: 0 0 pxem(10px);

      @include respond-to('xxs') {
        font-size: 3.236em;
      }

      @include respond-to('xs') {
        font-size: 4.236em;
      }

      @include respond-to('sm') {
        font-size: 5.236em;
      }

      @include respond-to('md') {
        font-size: 6.236em;
      }

      @include respond-to('xxl') {
        margin: 0 0 pxem(20px);
      }
    }

    h2 {
      font-size: 2.236em;
      margin-bottom: pxem(-24.55px);
      position: relative;
      text-transform: uppercase;
      top: -74px;

      @include respond-to('xs') {
        font-size: 3.236em;
        top: -85px;
      }

      @include respond-to('sm') {
        font-size: 4.236em;
        // font-size: 4.236em;
        margin-bottom: pxem(-11px);
        top: -91px;
      }

      @include respond-to('md') {
        font-size: 5.236em;
        // font-size: 6.854em;
        margin-bottom: pxem(-5px);
        top: -100px;
      }

      &.primary-header {
        color: $secondary-bg-color;
      }

      &.secondary-header {
        color: $primary-bg-color;
      }
    }

    h3 {
      font-size: 2.618em;
    }

    h4 {
      font-size: 1.618em;
    }

    h5 {
      font-size: 1.309em;
    }

    h6 {
      font-size: 1.1545em;
    }

    p {
      font-size: 0.800em;
      // font-size: 1em;
    }
  }
}
