// _tired-browser.scss

////= Tired Browser Module
//============================================================================//

.tired-browser {
  background: $grey;
  color: $black;
  margin: pxrem(3.2px) 0;
  padding: pxrem(3.2px) 0;
}
