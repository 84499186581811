// _core.scss

////= Core Setup
//============================================================================//

/// *1* – Setup box model of border-box on the root element
/// *2* – Make all elements from the DOM inherit from the root box-sizing

html {
  // box-sizing: border-box;      /// *1* ///
  font-family: sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

// *,                             /// *2* ///
// *::before,                     /// *2* ///
// *::after {                     /// *2* ///
//   box-sizing: inherit;         /// *2* ///
// }                              /// *2* ///

body {
  color: $primary-color;
  font: ($body-font-size / 1) $body-font;
  margin: 0;
}

a,
button,
i {
  -webkit-transition: $primary-duration;
  transition: $primary-duration;
}

a,
button {
  color: inherit;
}

a {
  background-color: transparent;
  color: $quaternary-color;
  display: inline-block;
  position: relative;
  text-decoration: none;
}

// audio,
// canvas,
// progress,
// video {
//   display: inline-block;
//   vertical-align: baseline;
// }

// audio:not([controls]) {
//   display: none;
//   height: 0;
// }

b,
strong {
  font-weight: bold;
}

blockquote,
dfn {
  font-style: italic;
}

// input,
// optgroup,
// select,
// textarea {
//   color: inherit;
//   font: inherit;
//   margin: 0;
// }

button {
  background: none;
  border: 0;
  cursor: pointer;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  overflow: visible;

  &:focus {
    outline: 0;
  }
}

code {
  color: $quaternary-color;
  font-family: $code-font;
}

h1,
h2,
h3,
h4,
h5,
p,
ol,
ul,
blockquote,
button,
figure {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
}

iframe,
img {
  border: 0;
}

img {
  // // display: block;
  height: auto; // default
  margin: 0 auto;
  // // max-height: 80vh;
  // max-width: 100%; // default
}

li {
  display: block;
}

pre,
textarea {
  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;
}

small {
  // font-size: 0.618em;
  font-size: 0.9em;
}

sub,
sup {
  font-size: $sm-font-size;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.225em;
}

sup {
  top: -0.45;
}

[hidden],
template {
  display: none;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

::-moz-selection {
  background-color: rgba($pacific-blue, 0.3);
  opacity: 0;
}

::selection {
  background-color: rgba($pacific-blue, 0.3);
  opacity: 0;
}

/// Attributes for Simple Line Icons
/// @author Sabbir Ahmed
/// @link https://github.com/thesabbir/simple-line-icons
.sli-style {
  // color: $quaternary-color;
  font-family: $sli-icon;
  font-size: pxem(30px);
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-transform: none;
}
